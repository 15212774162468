import request from '@/utils/request'

export function changePassword(params) {
  return request({
    url: '/user/account/changePassword',
    method: 'POST',
    params: params
  })
}

export function findAccountByInfo(data) {
  return request({
    url: '/user/account/findAccountByInfo',
    method: 'POST',
    data
  })
}

export function resetPassword(data) {
  return request({
    url: '/user/account/resetPassword',
    method: 'POST',
    data
  })
}
