<template>
  <div class="notary-container">
    <div class="notary-main">
      <div class="title">请选择您本次要进入的主体</div>
      <div>
        <div
          v-for="item of data"
          :key="item.id"
          :class="['item', { active: item.id === active }]"
          @click="handleChoose(item)"
        >
          <div class="avatar">
            <img :src="item.image" />
            <div v-if="item.id === 1" class="def-item">默认</div>
          </div>
          <div class="office-name">{{ item.value }}</div>
          <div v-show="item.id === active" class="el-icon-success" />
          <div v-show="item.id !== active" class="el-icon-circle icon" />
        </div>
      </div>
      <el-button type="primary" class="col">进入</el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotaryOffice',
  data() {
    return {
      active: 1,
      data: [
        {
          id: 1,
          value: '厦门市鹭江公证处',
          image:
            'http://uat-portal.fxnotary.com/api/file/view?code=wKhY42CciV2ANQ7zAAAKIlneq8w772&token=eyJhbGciOiJIUzUxMiJ9.eyJ1aWQiOiIzODU3NDk4NzQzMDg5NzI1NDQiLCJzdWIiOiJjc2d6eSIsInV0eXBlIjoiMyIsIm5pZCI6IjMxNjQwNDYwMTI0MDg2NjgxNiIsInBpZCI6IjM1MTkyMjQzMTA3NTAyNDg5NiIsImFpZCI6IjQyNDQwNzkyOTY5NzAyMTk1MiIsImp0aSI6IjI5OTEyNzlmNzZhZjQ2OGNhNTA0YWEwYjViM2NkNDg5IiwiY2lkIjoiMzg0ODc3MDUxMzU0MjEwMzA0In0.9aGSLzn_H2hBtd8qwZAPzjumLaok_Dt0pqjPiwgn4G1xUJXrMm7FOagZQ7HQCBkdruTU4EwuyKdNjKFdvIn7Zg'
        },
        {
          id: 2,
          value: '厦门市云尚公证处',
          image:
            'http://uat-portal.fxnotary.com/api/file/view?code=wKhY42CciV2ANQ7zAAAKIlneq8w772&token=eyJhbGciOiJIUzUxMiJ9.eyJ1aWQiOiIzODU3NDk4NzQzMDg5NzI1NDQiLCJzdWIiOiJjc2d6eSIsInV0eXBlIjoiMyIsIm5pZCI6IjMxNjQwNDYwMTI0MDg2NjgxNiIsInBpZCI6IjM1MTkyMjQzMTA3NTAyNDg5NiIsImFpZCI6IjQyNDQwNzkyOTY5NzAyMTk1MiIsImp0aSI6IjI5OTEyNzlmNzZhZjQ2OGNhNTA0YWEwYjViM2NkNDg5IiwiY2lkIjoiMzg0ODc3MDUxMzU0MjEwMzA0In0.9aGSLzn_H2hBtd8qwZAPzjumLaok_Dt0pqjPiwgn4G1xUJXrMm7FOagZQ7HQCBkdruTU4EwuyKdNjKFdvIn7Zg'
        }
      ]
    }
  },

  methods: {
    handleChoose(item) {
      this.active = item.id
    }
  }
}
</script>
<style lang="scss" scoped>
$active: #3388ff;

.notary-container {
  width: 1200px;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: #fff;
}

.notary-main {
  display: flex;
  width: 1200px;
  height: 100%;
  padding: 50px 380px;
  flex-direction: column;
  justify-content: center;
  background: #fff;
}

.title {
  font-size: 20px;
  font-weight: bold;
  color: #324658;
}

.item {
  display: inline-block;
  margin: 5% 10%;
  cursor: pointer;

  .icon {
    width: 12px;
    height: 12px;
    border: 1px solid #dce5ec;
    border-radius: 50%;
  }

  .office-name {
    height: 30px;
    line-height: 30px;
  }

  .avatar {
    position: relative;
    display: flex;
    width: 120px;
    height: 120px;
    margin: 5% auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #dce5ec;
    background: #fff;
    overflow: hidden;

    img {
      height: 50%;
      width: 50%;
    }
  }
}

.col {
  width: 70%;
  margin: 0 auto;
}

.el-icon-success {
  font-size: 14px;
  color: $active;
}

.active {
  .office-name {
    color: $active;
    transition: all 0.2s;
  }

  .avatar {
    border-color: $active;
    transition: all 0.2s;
  }
}

.def-item {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 14px;
  background: #f2f6f9;
  color: #324658;
  // transform: scale(0.8);
}
</style>
