<template>
  <div v-loading="loading" class="pwd-container">
    <div v-show="!success" class="pwd-main">
      <div class="title">欢迎加入</div>
      <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="0px">
        <el-form-item v-if="accountType == 2" prop="phoneNumber">
          <el-input
            v-model="form.phoneNumber"
            class="login-username"
            type="text"
            auto-complete="off"
            placeholder="请输入手机号"
          />
        </el-form-item>
        <el-form-item v-if="accountType == 2" prop="vcode">
          <el-input v-model="form.vcode" auto-complete="off" placeholder="请输入图形验证码" class="login-code-input" />
          <div class="login-code">
            <img :src="codeUrl" @click="getImageCode" />
          </div>
        </el-form-item>
        <el-form-item v-if="accountType == 4" prop="username">
          <el-input
            v-model="form.username"
            class="login-username"
            type="text"
            auto-complete="off"
            placeholder="请输入用户名"
          />
        </el-form-item>
        <el-form-item v-if="accountType == 4" prop="email">
          <el-input
            v-model="form.email"
            class="login-username"
            type="text"
            auto-complete="off"
            placeholder="请输入有效邮箱"
          />
        </el-form-item>
        <el-form-item prop="code">
          <el-input v-model="form.code" auto-complete="off" placeholder="请输入验证码" class="login-code-input" />
          <div class="login-code">
            <el-button
              :disabled="phoneCodeCountDownValue > 0"
              :loading="vcodeLoading"
              type="primary"
              class="code-btn"
              @click.native.prevent="getCode"
            >
              <span>{{ phoneCodeCountDownValue === 0 ? '发送验证码' : phoneCodeCountDownValue }}</span>
            </el-button>
          </div>
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="form.password"
            class="login-username"
            type="password"
            auto-complete="off"
            placeholder="请输入密码"
          />
        </el-form-item>
        <el-form-item prop="confirmPwd">
          <el-input
            v-model="form.confirmPwd"
            type="password"
            auto-complete="off"
            placeholder="请再次输入密码"
            class="login-username"
          />
        </el-form-item>
        <el-form-item prop="registerNotaryOfficeId">
          <el-select
            v-model="form.registerNotaryOfficeId"
            placeholder="选择公证处"
            filterable
            :filter-method="dataFilter"
            class="login-username"
          >
            <el-option v-for="item of notaryOpts" :key="item.id" :value="item.id" :label="item.name" />
          </el-select>
          <div class="tips">可以选择住所地、经常居住地、行为地或者事实发生地的公证机构</div>
        </el-form-item>
        <el-form-item>
          <el-checkbox v-model="agree" class="agreement-item">
            我已阅读并接受
            <span>《公证云用户服务协议》</span>
          </el-checkbox>
          <el-button type="primary" class="col" @click="registerAccount">立即注册</el-button>
        </el-form-item>
        <el-form-item>
          <el-button v-show="accountType == 2" type="text" class="login-btn1" @click="changeAccountType(4)">
            跳转到机构用户注册
          </el-button>
          <el-button v-show="accountType == 4" type="text" class="login-btn1" @click="changeAccountType(2)">
            跳转到个人用户注册
          </el-button>
          <el-button type="text" class="login-btn2">直接登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-show="success" class="pwd-main-success">
      <div class="avatar-wrap">
        <i class="icon-success" />
        <div class="title">恭喜您注册成功</div>
      </div>
      <el-button type="primary" class="col" @click="goApprove">立即认证</el-button>
    </div>
  </div>
</template>

<script>
import { getCodeImg } from '@/api/login'
import { getOfficeAll, pageRegister } from '@/api/register'
import { normalSendCode } from '@/api/common'
import { mapMutations } from 'vuex'
import { setToken } from '../utils/auth'

export default {
  components: {},
  data() {
    const confirmPass = (rule, value, callback) => {
      if (value) {
        if (this.form.password !== value) {
          callback(new Error('两次输入的密码不一致'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请再次输入密码'))
      }
    }
    return {
      success: false,
      phoneCodeDisabled: false,
      phoneCodeCountDownValue: 0,
      timer: null,
      loading: false,
      vcodeLoading: false,
      agree: false,
      accountType: 2,
      form: {
        username: '',
        email: '',
        phoneNumber: '',
        vcode: '',
        code: '',
        password: '',
        confirmPwd: '',
        vcodeUuid: '',
        registerNotaryOfficeId: '',
        bizId: '' //验证码业务ID
      },
      rules: {
        username: { required: true, trigger: 'blur', validator: this.$utils.validUserName },
        email: { required: true, trigger: 'blur', message: '请输入邮箱' },
        vcode: { required: true, trigger: 'blur', message: '请输入图形验证码' },
        code: { required: true, trigger: 'blur', message: '请输入验证码' },
        phoneNumber: { required: true, trigger: 'blur', validator: this.$utils.validPhone },
        password: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 8, max: 16, validator: this.$utils.validPassword }
        ],
        confirmPwd: [{ required: true, validator: confirmPass, trigger: 'blur' }],
        registerNotaryOfficeId: { required: true, trigger: 'blur', message: '请选择公证处' }
      },
      ruleGroup: {
        step1: ['phoneNumber', 'vcode', 'code'],
        step2: ['password', 'confirmPwd']
      },
      codeUrl: '',
      notaryOpts: [],
      allNotaryOffice: []
    }
  },
  created() {
    this.getImageCode()
    this.getAllNotaryOffice()
  },
  methods: {
    changeAccountType(val) {
      this.accountType = val
    },
    /* 获取手机验证码 */
    getCode() {
      const phoneVerify = this.accountType === 2 ? true : false
      const value = phoneVerify ? this.form.phoneNumber : this.form.email
      normalSendCode({
        value: value,
        phoneVerify: phoneVerify
      })
        .then((res) => {
          this.loading = false
          if (res.code === 0) {
            this.form.bizId = res.data.bizId
            this.countDownPhoneCode()
          } else {
            this.$notify({
              title: res.message,
              type: 'error',
              duration: 2500
            })
          }
        })
        .catch((err) => {
          this.loading = false
          console.error(err.response.data.message)
        })
    },
    /* 获取图形验证码 */
    getImageCode() {
      getCodeImg().then((res) => {
        this.codeUrl = 'data:image/gif;base64,' + res.img
        this.form.vcodeUuid = res.uuid
      })
    },
    /* 加载公证处 */
    getAllNotaryOffice() {
      getOfficeAll().then((res) => {
        this.notaryOpts = res.data
        this.allNotaryOffice = res.data
      })
    },
    dataFilter(val) {
      if (val) {
        //val存在
        this.notaryOpts = this.notaryOpts.filter((item) => {
          if (!!~item.name.indexOf(val) || !!~item.name.toUpperCase().indexOf(val.toUpperCase())) {
            return true
          }
        })
      } else {
        //val为空时，还原数组
        this.notaryOpts = this.allNotaryOffice
      }
    },
    /**
     * 对手机验证码进行冷却限制
     */
    countDownPhoneCode() {
      if (this.timer) {
        clearInterval(this.timer)
      }

      this.phoneCodeDisabled = true
      this.phoneCodeCountDownValue = 60

      this.timer = setInterval(() => {
        this.phoneCodeCountDownValue = this.phoneCodeCountDownValue - 1
        if (this.phoneCodeCountDownValue <= 0) {
          clearInterval(this.timer)
          this.phoneCodeDisabled = false
        }
      }, 1000)
    },
    //注册接口
    registerAccount() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (!this.agree) {
            this.$notify({
              title: '请先阅读并勾选“公证云用户服务协议”',
              type: 'error',
              duration: 2500
            })
            return
          }
          // 个人账号时用户名为手机号
          if (this.accountType === 2) {
            this.form.username = this.form.phoneNumber
          }
          pageRegister({
            ...this.form,
            accountType: this.accountType
          })
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.setToken(res.data.token)
                this.setUser(res.data.user)
                setToken(res.data, false)
                this.success = true
              } else {
                this.$notify({
                  title: res.message,
                  type: 'error',
                  duration: 2500
                })
              }
            })
            .catch((err) => {
              this.loading = false
              console.error(err)
              //console.error(err.response.data.message)
            })
        }
      })
    },
    goApprove() {
      this.$router.push({ path: 'approve' })
    },
    ...mapMutations({
      setToken: 'SET_TOKEN',
      setUser: 'SET_USER'
    })
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/login.scss';

.title {
  text-align: center;
  margin-bottom: 10%;
}
.agreement-item {
  font-size: 13px;
  color: #989898;

  span {
    color: #3388ff;
  }
}

.tips {
  margin-bottom: 5%;
  font-size: 12px;
  color: #d2d2d2;
}

.login-btn1 {
  color: #2e2e2e;

  &:hover {
    color: #66b1ff;
  }
}
.login-btn2 {
  float: right;
}
</style>
