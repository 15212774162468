import request from '@/utils/request'

//公证处的所有信息
export function getOfficeAll() {
  return request({
    url: '/base/notaryOffice/getOfficeAll',
    method: 'post'
  })
}

//页面注册账号
export function pageRegister(data) {
  return request({
    url: '/user/account/pageRegister',
    method: 'post',
    data
  })
}
