<template>
  <div v-loading="loading" class="pwd-container">
    <div v-show="!success" class="pwd-main">
      <div class="avatar-wrap">
        <i class="icon-lock nc-avatar" />
        <div class="title">请设置您的新密码</div>
      </div>
      <div class="user-info">
        <span>用户名：{{ form.username }}</span>
        <router-link to="/findpassword" class="find-ps__a">
          <span class="find-ps">
            <i class="el-icon-warning" />
            忘记密码?
          </span>
        </router-link>
      </div>
      <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="0px">
        <el-form-item prop="oldPassword">
          <el-input
            v-model="form.oldPassword"
            class="login-username"
            type="password"
            auto-complete="off"
            placeholder="请输入原密码"
          />
        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input
            v-model="form.newPassword"
            class="login-username"
            type="password"
            auto-complete="off"
            placeholder="请输入新密码"
          />
        </el-form-item>
        <el-form-item prop="confirmPwd">
          <el-input
            v-model="form.confirmPwd"
            type="password"
            auto-complete="off"
            placeholder="请再次输入新密码"
            class="login-username"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="col mt" @click="save">确认修改</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div v-show="success" class="pwd-main-success">
      <div class="avatar-wrap">
        <i class="icon-success" />
        <div class="title">操作成功</div>
      </div>
      <el-button type="primary" class="col" @click="goLogin">返回登录</el-button>
    </div>
  </div>
</template>

<script>
import { changePassword } from '@/api/updatePassword'

export default {
  name: 'UpdatePassword',
  data() {
    const confirmPass = (rule, value, callback) => {
      if (value) {
        if (this.form.newPassword !== value) {
          callback(new Error('两次输入的密码不一致'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请再次输入密码'))
      }
    }
    return {
      success: false,
      loading: false,
      form: {
        username: 'username',
        oldPassword: '',
        newPassword: '',
        confirmPwd: ''
      },
      rules: {
        oldPassword: { required: true, message: '请输入原密码', trigger: 'blur' },
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 8, max: 16, validator: this.$utils.validPassword }
        ],
        confirmPwd: [{ required: true, validator: confirmPass, trigger: 'blur' }]
      }
    }
  },

  methods: {
    save() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          changePassword({
            username: this.form.username,
            oldPassword: this.form.oldPassword,
            newPassword: this.form.newPassword
          })
            .then((res) => {
              if (res.code === 0) {
                setTimeout(() => {
                  console.warn('操作成功')
                  this.loading = false
                  this.success = true
                }, 1000)
              } else {
                this.loading = false
                this.$notify({
                  title: res.message,
                  type: 'error',
                  duration: 2500
                })
              }
            })
            .catch((err) => {
              this.loading = false
              console.error(err.response.data.message)
            })
        }
      })
    },
    goLogin() {
      this.$router.push({ path: '/login' })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../assets/css/login.scss';

.user-info {
  padding: 30px 8% 10px;

  a {
    float: right;
  }
}
</style>
