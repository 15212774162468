<template>
  <div class="pwd-container">
    <div class="pwd-main">
      <div class="forget-pass">
        <el-steps :active="active" finish-status="success">
          <el-step class="step1" title="身份验证" />
          <el-step class="step2" title="选择账号" />
          <el-step class="step3" title="找回密码" />
          <el-step class="step4" title="完成" />
        </el-steps>
        <!-- 身份验证 -->
        <el-form v-show="active === 0" ref="form" :model="form" :rules="rules" size="small">
          <el-form-item prop="code" class="inline">
            <el-select v-model="form.code" placeholder="请选择" style="width: 140px" @change="changeUniqueFlag">
              <el-option v-for="item in uniqueFlags" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
          </el-form-item>
          <el-form-item prop="value" class="inline info-input">
            <el-input v-model="form.value" class="phone__input" :placeholder="changePlaceholder" />
          </el-form-item>
          <el-form-item prop="imageCode">
            <el-input v-model="form.imageCode" auto-complete="off" placeholder="验证码" class="login-code-input" />
            <div class="login-code">
              <img :src="imageCodeUrl" @click="getImageCode" />
            </div>
          </el-form-item>
        </el-form>
        <!-- 选择账号 -->
        <el-form v-show="active === 1" size="small" label-width="88px">
          <el-form-item>
            <div class="mes">请选择需要找回密码的账号</div>
          </el-form-item>
          <el-button
            v-for="item in matchAccounts"
            :key="item.id"
            :label="item.username"
            :value="item.id"
            type="primary"
            class="username-btn"
            @click="chooseAccount(item)"
          >
            {{ item.username }}
          </el-button>
        </el-form>
        <!-- 验证验证码 -->
        <el-form
          v-show="active === 2"
          ref="resetPassWordForm"
          :model="resetPassWordForm"
          :rules="rules"
          size="small"
          label-width="80px"
        >
          <el-form-item v-if="emailFlag" label="邮箱">
            <div>
              <span>{{ resetPassWordForm.value | emailSecretLize }}</span>
            </div>
          </el-form-item>
          <el-form-item v-if="!emailFlag" label="手机号">
            <div class="global-height phone-form-item">
              <span>{{ resetPassWordForm.value | phoneSecretLize }}</span>
            </div>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input v-model="resetPassWordForm.code" autocomplete class="code__input" placeholder="请输入验证码" />
            <el-button type="text" class="code-btn" :disabled="codeCountDownValue > 0" @click="getCode">
              <span>{{ codeCountDownValue === 0 ? '获取验证码' : codeCountDownValue }}</span>
            </el-button>
            <el-tooltip key="tool1" class="item" effect="light" placement="right">
              <div v-if="!emailFlag" slot="content" class="information">
                <span v-for="(item, index) in codeTips" :key="index">
                  {{ item }}
                </span>
              </div>
              <div v-if="emailFlag" slot="content" class="information">
                <span v-for="(item, index) in emailCodeTips" :key="index">
                  {{ item }}
                </span>
              </div>
              <i class="el-icon-question code-help global-height" />
            </el-tooltip>
          </el-form-item>
          <el-form-item label="新密码" prop="newPass">
            <el-input v-model="resetPassWordForm.newPass" type="password" auto-complete="on" />
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPass">
            <el-input v-model="resetPassWordForm.confirmPass" type="password" auto-complete="on" />
          </el-form-item>
        </el-form>
        <el-button
          v-if="active !== 3 && active !== 1"
          type="primary"
          class="col mt next-btn global-height"
          @click="goNext"
        >
          下一步
        </el-button>
        <div v-if="active === 3" class="avatar-wrap global-height">
          <i class="icon-success" />
          <div class="title">操作成功</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCodeImg } from '@/api/login'
import { normalSendCode } from '@/api/common'
import { findAccountByInfo, resetPassword } from '@/api/updatePassword'

export default {
  filters: {
    emailSecretLize: function (value) {
      if (!value) return ''
      if (value.length > 9) {
        value = value.substr(0, 2) + '****' + value.substr(value.length - 8, value.length - 1)
      }
      return value
    },
    phoneSecretLize: function (value) {
      if (!value) return ''
      if (value.length >= 5) {
        value = value.substr(0, 3) + '******' + value.substr(value.length - 3, value.length - 1)
      }
      return value
    }
  },
  data() {
    const confirmPass = (rule, value, callback) => {
      if (value) {
        if (this.resetPassWordForm.newPass !== value) {
          callback(new Error('两次输入的密码不一致'))
        } else {
          callback()
        }
      } else {
        callback(new Error('请再次输入密码'))
      }
    }
    const validPassword = (rule, value, callback) => {
      const reg = /^(?![0-9]+$)(?![A-z]+$)(?![@!.~#$%/\\^&*]+$)[0-9A-z@!.~#$%/\\^&*]{8,16}$/
      if (!reg.test(value)) {
        callback(new Error('请输入8~16位英文、数字或常用符号，字母区分大小写'))
      }
      callback()
    }
    const uniqueValueRule = (rule, value, callback) => {
      if (value === undefined || value === null || value === '') {
        callback(new Error('不能为空'))
      } else {
        if (
          this.uniqueValueMessage !== undefined &&
          this.uniqueValueMessage !== null &&
          this.uniqueValueMessage !== ''
        ) {
          callback(new Error(this.uniqueValueMessage))
        } else {
          callback()
        }
      }
    }
    const imageCodeRule = (rule, value, callback) => {
      if (value === undefined || value === null || value === '') {
        callback(new Error('请输入验证码'))
      } else {
        if (this.imageCodeMessage !== undefined && this.imageCodeMessage !== null && this.imageCodeMessage !== '') {
          callback(new Error(this.imageCodeMessage))
        } else {
          callback()
        }
      }
    }
    return {
      accountId: '',
      loading: false,
      title: '',
      imageCodeUrl: '',
      uniqueValueMessage: null,
      imageCodeMessage: null,
      form: { code: 'username', uniqueLabel: '用户名', value: '', imageCode: '', imageCodeUuid: '' },
      resetPassWordForm: { value: '', code: '', bizId: '', newPass: '', confirmPass: '' },
      rules: {
        value: [{ required: true, trigger: 'blur', validator: uniqueValueRule }],
        imageCode: [{ required: true, trigger: 'blur', validator: imageCodeRule }],
        oldPass: [{ required: true, message: '请输入旧密码', trigger: 'blur' }],
        newPass: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 8, max: 16, validator: validPassword }
        ],
        confirmPass: [{ required: true, validator: confirmPass, trigger: 'blur' }]
      },
      emailFlag: false,
      active: 0,
      codeCountDownValue: 0,
      countDown: null,
      codeTips: [
        '没收到短信验证码？',
        '1、网络通讯异常可能会造成短信丢失，请重新获取或稍后再试。',
        '2、请核实手机是否已欠费停机，或者屏蔽了系统短信。',
        '3、如果手机已丢失或停用， 请选择其他验证方式。',
        '4、您也可以尝试将SIM卡移动到另一部手机，然后重试。'
      ],
      emailCodeTips: [
        '没收到邮箱验证码？',
        '1、网络通讯异常可能会造成邮件丢失，请重新获取或稍后再试。',
        '2、请核实邮箱地址是否正确，或者屏蔽了系统短信。',
        '3、请确认验证邮件是否误入垃圾箱内。'
      ],
      matchAccounts: [],
      uniqueFlags: [
        {
          value: 'username',
          label: '用户名'
        },
        {
          value: 'phone',
          label: '手机号'
        },
        {
          value: 'email',
          label: '邮箱'
        },
        {
          value: 'idNo',
          label: '证件号'
        }
      ],
      time: -1
    }
  },
  computed: {
    changePlaceholder() {
      return '请输入' + this.form.uniqueLabel
    }
  },
  watch: {},
  created() {
    this.getImageCode()
  },
  methods: {
    getImageCode() {
      getCodeImg().then((res) => {
        this.imageCodeUrl = 'data:image/gif;base64,' + res.img
        this.form.imageCodeUuid = res.uuid
      })
    },
    changeUniqueFlag(val) {
      let obj = {}
      obj = this.uniqueFlags.find((item) => {
        return item.value === val
      })
      this.form.uniqueLabel = obj.label
    },
    getCode() {
      normalSendCode({
        value: this.resetPassWordForm.value,
        phoneVerify: !this.emailFlag
      })
        .then((res) => {
          this.loading = false
          if (res.code === 0) {
            this.resetPassWordForm.bizId = res.data.bizId
            this.countDownPhoneCode()
          } else {
            this.$notify({
              title: res.message,
              type: 'error',
              duration: 2500
            })
          }
        })
        .catch((err) => {
          this.loading = false
          console.error(err.response.data.message)
        })
    },
    /**
     * 对手机验证码进行冷却限制
     */
    countDownPhoneCode() {
      if (this.countDown) {
        clearInterval(this.countDown)
      }
      this.codeCountDownValue = 60
      this.countDown = setInterval(() => {
        this.codeCountDownValue = this.codeCountDownValue - 1
        if (this.codeCountDownValue <= 0) {
          clearInterval(this.countDown)
        }
      }, 1000)
    },
    goNext() {
      if (this.active === 0) {
        this.goStep2()
      } else if (this.active === 2) {
        this.goStep3()
      }
    },
    chooseAccount(data) {
      this.accountId = data.id
      this.emailFlag = data.type == 4
      if (this.emailFlag) {
        this.resetPassWordForm.value = data.email
      } else {
        this.resetPassWordForm.value = data.phoneNumber
      }
      if (
        this.resetPassWordForm.value === undefined ||
        this.resetPassWordForm.value === null ||
        this.resetPassWordForm.value === ''
      ) {
        this.$notify({
          title: this.emailFlag ? '此管理员用户未维护邮箱，请先维护邮箱' : '此用户未维护手机号',
          type: 'error',
          duration: 2500
        })
      } else {
        this.active++
      }
    },
    goStep2() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.loading = true
          findAccountByInfo({
            ...this.form
          })
            .then((res) => {
              if (res.code === 0) {
                this.emailFlag = res.data.emailFlag
                this.active++
                if (res.data.uniqueFlag) {
                  this.active++
                  this.accountId = res.data.accounts[0].id
                  if (this.emailFlag) {
                    this.resetPassWordForm.value = res.data.accounts[0].email
                  } else {
                    this.resetPassWordForm.value = res.data.accounts[0].phoneNumber
                  }
                } else {
                  this.matchAccounts = res.data.accounts
                }
              } else if (res.code === 50007 || res.code === 50006) {
                this.imageCodeMessage = res.message
                this.getImageCode()
              } else if (res.code === 50014) {
                this.uniqueValueMessage = res.message
                this.getImageCode()
              } else {
                this.getImageCode()
                this.$notify({
                  title: res.message,
                  type: 'error',
                  duration: 2500
                })
              }
              this.$refs.form.validate(() => {})
              this.imageCodeMessage = null
              this.uniqueValueMessage = null
              this.loading = false
            })
            .catch((err) => {
              this.loading = false
              console.error(err.response.data.message)
            })
        } else {
          return false
        }
      })
    },
    goStep3() {
      this.resetPassword()
    },
    resetPassword() {
      this.$refs['resetPassWordForm'].validate((valid) => {
        if (valid) {
          this.loading = true
          resetPassword({
            accountId: this.accountId,
            password: this.resetPassWordForm.confirmPass,
            bizId: this.resetPassWordForm.bizId,
            code: this.resetPassWordForm.code,
            value: this.resetPassWordForm.value
          })
            .then((res) => {
              if (res.code === 0) {
                this.resetForm('resetPassWordForm')
                this.active++
                this.time = 5
                let countDown = setInterval(() => {
                  this.time -= 1
                  if (this.time <= 0) {
                    clearInterval(countDown)
                    //this.goLogin()
                  }
                }, 1000)
              } else {
                this.$notify({
                  title: res.message,
                  type: 'error',
                  duration: 2500
                })
              }
            })
            .catch((err) => {
              this.loading = false
              console.error(err.response.data.message)
            })
        } else {
          return false
        }
      })
    },
    resetForm(ref) {
      this.$refs[ref].resetFields()
      this[ref] = { code: 'username', uniqueLabel: '用户名', value: '', imageCode: '', imageCodeUuid: '' }
      this[ref] = { value: '', code: '', bizId: '', newPass: '', confirmPass: '' }
    },
    goLogin() {
      this.$store.dispatch('LogOut').then(() => {
        location.reload() // 为了重新实例化vue-router对象 避免bug
      })
      this.$router.replace('/')
    }
  }
}
</script>

<style scoped lang="scss">
@import '../assets/css/login.scss';

$success-clr: #1890ff;

.pwd-container {
  flex: 1;
  align-items: baseline;
  min-height: 100%;
}

.pwd-main {
  padding: 50px;
}

::v-deep .el-step.is-horizontal {
  flex-basis: 40% !important;

  &.is-flex {
    flex-basis: 20% !important;
  }
}

::v-deep .el-step__main {
  white-space: normal;
  text-align: left;
  display: inline-block;
  position: absolute;
  top: -7px;
  left: 40px;
  background: #fff;
  padding: 0 20px;
  font-size: 16px;

  /deep/ .el-step__title,
  /deep/ .el-step__title.is-process {
    color: #324658;
    font-weight: normal;
  }
}

::v-deep .el-step__head {
  &.is-success {
    color: $success-clr;
    border-color: $success-clr;

    .el-step__line {
      background: $success-clr;
    }

    .el-step__icon.is-text {
      background: $success-clr;
      border-color: $success-clr;
    }
  }

  .el-step__line {
    left: 20px;
    right: 20px;
    background-color: #f2f4f6;
  }

  .el-step__icon.is-text {
    height: 40px;
    width: 40px;
    margin-top: -10px;
    background: #f2f4f6;
    border-color: #f2f4f6;
  }

  .el-step__icon-inner {
    font-size: 24px;
    color: #fff;
  }

  &.is-process {
    .el-step__icon.is-text {
      background: #1890ff;
      border-color: #1890ff;
    }
  }
}

.step1 /deep/.el-icon-check:before {
  content: '1';
}
.step2 /deep/.el-icon-check:before {
  content: '2';
}

.forget-pass {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: auto;
  background-color: #fff;

  .el-steps {
    width: 710px;
  }
  .el-form {
    width: 100%;
    margin: auto;
    padding: 50px 365px 0;
  }

  .inline {
    display: inline-block;
  }

  .info-input {
    margin-left: 10px;
  }

  .phone__input {
    width: 220px;
  }
}

.phone-form-item {
  display: flex;
  align-items: center;
}

.login-code-input {
  width: 49%;
}

.code__input {
  // width: 250px;
  /deep/ .el-input__inner {
    padding-right: 80px;
  }
}

.code-btn {
  position: absolute;
  right: 0;
}

.information {
  > span {
    display: block;
    line-height: 22px;
    color: #409eff;
  }
}

.code-help {
  position: absolute;
  display: flex;
  top: 0;
  right: -25px;
  font-size: 18px;
  padding-left: 5px;
  align-items: center;
  color: #1890ff;
}

.next-btn {
  width: 370px;
  display: block;
  margin: 20px 0;
}
.username-btn {
  display: block;
  margin: 0 auto 5px;
  width: 200px;
}

.el-step__title.is-success {
  color: #1890ff;
}

.avatar-wrap {
  margin: 60px 0;

  div {
    margin: 20px;
  }
}

.desc-wrap {
  height: 60px;
  background: #f9fafc;
  line-height: 22px;
  padding: 9px 20px;

  > div:first-child {
    color: #324658;
    font-size: 13px;

    i {
      color: #1890ff;
    }
  }
  > div:last-child {
    color: #788ea4;
    font-size: 12px;
  }
}
</style>
