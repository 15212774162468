<template>
  <div ref="loadingMask" class="findpassword-content">
    <!-- prettier-ignore -->
    <iframe
      v-if="url"
      :src="url+'?clientFlag='+clientFlag"
      width="100%"
      height="100%"
      style="border: none"
    />
    <div v-else>
      <span>页面未找到 Page Not Found</span>
    </div>
  </div>
</template>
<script>
import maskMixins from '@/mixins/mask'
export default {
  name: 'LegalFindPassword',
  mixins: [maskMixins],
  data() {
    return {
      loading: false,
      url: null,
      clientFlag: this.$route.query.clientFlag,
      applicationId: this.$route.query.applicationId,
    }
  },
  created() {},
  mounted() {
    this.loading = true
    this.$store
      .dispatch('LoadConfig')
      .then((res) => {
        this.loading = false
        this.url = (res.findPassword || {}).url
      })
      .catch((e) => {
        this.loading = false
        console.error(e)
      })

    this.initEvent()
  },
  methods: {
    initEvent() {
      window.addEventListener('message', (res) => {
        if (res.data?.msg === 'resetPs') {
          if (this.clientFlag === 'PLATFORM_OPERATION') {
            this.$router.push('/login/platformOperation?applicationId=' + this.applicationId)
          } else {
            this.$router.push('/login/legalService?applicationId=' + this.applicationId)
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.findpassword-content {
  height: 100%;
}
</style>
