import { Loading } from 'element-ui'

export default {
  data() {
    return {
      loading: false,
      loadingMask: null,
      loadingMaskName: 'loadingMask',
      loadingMaskText: '加载中...'
    }
  },
  watch: {
    loading(val) {
      let mask = this.loadingMask
      if (val && !mask) {
        const content = this.$refs[this.loadingMaskName]
        mask = this.loadingMask = Loading.service({
          target: content,
          text: this.loadingMaskText || ''
        })
      } else if (mask && !val) {
        mask.close()
        this.loadingMask = null
      }
    }
  }
}
