<template>
  <h1 data-v-19c9d02c="" style="margin-top: 16px;">欢迎来到客户中心</h1>
</template>

<script>

export default {
  name: 'default'
}
</script>
