<template>
  <div style="height: 100%">
    <div class="header">
      <div class="width">
        <!-- <img src="../../static/img/logo.png" /> -->
        <h1>法信公证云客户中心</h1>
      </div>
    </div>
    <div class="content">
      <div class="swiper-container">
        <div class="swiper-slide"><img src="../assets/images/img2.png" /></div>
      </div>
      <div class="login-form">
        <el-form ref="loginForm" :model="loginForm" :rules="loginRules" label-position="left" label-width="0px">
          <h2>欢迎登录</h2>
          <el-form-item prop="username">
            <el-input
              v-model="loginForm.username"
              class="login-username"
              type="text"
              size="medium"
              auto-complete="off"
              placeholder="账号"
            >
              <!-- <svg-icon slot="prefix" icon-class="user" class="el-input__icon input-icon" /> -->
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              v-model="loginForm.password"
              class="login-password"
              type="password"
              size="medium"
              auto-complete="off"
              placeholder="密码"
              @keyup.enter.native="handleLogin"
            >
              <!-- <svg-icon slot="prefix" icon-class="password" class="el-input__icon input-icon" /> -->
            </el-input>
          </el-form-item>
          <el-form-item style="width: 100%">
            <el-button
              :loading="loading"
              size="medium"
              type="primary"
              style="width: 100%"
              @click.native.prevent="handleLogin"
            >
              <span v-if="!loading">登 录</span>
              <span v-else>登 录 中...</span>
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!--  底部  -->
    <div class="footer">
      <span>版权所有 ©2014-2020 法信公证云（厦门）科技有限公司 闽ICP备14011998号-6</span>
      <span class="footer-2">
        <a style="cursor: pointer" @click="jump">闽公网安备{{ bah }}号 | 查看备案信息</a>
      </span>
    </div>
  </div>
</template>

<script>
// import { encrypt } from '@/utils/rsaEncrypt'
import Config from '@/config'
import Cookies from 'js-cookie'

export default {
  name: 'Login',
  data() {
    const usernameRule = (rule, value, callback) => {
      if (value === undefined || value === null || value === '') {
        callback(new Error('用户名不能为空'))
      } else {
        if (this.usernameMessage !== undefined && this.usernameMessage !== null && this.usernameMessage !== '') {
          callback(new Error(this.usernameMessage))
        } else {
          callback()
        }
      }
    }
    const passwordRule = (rule, value, callback) => {
      if (value === undefined || value === null || value === '') {
        callback(new Error('密码不能为空'))
      } else {
        if (this.passwordMessage !== undefined && this.passwordMessage !== null && this.passwordMessage !== '') {
          callback(new Error(this.passwordMessage))
        } else {
          callback()
        }
      }
    }
    const codeRule = (rule, value, callback) => {
      if (value === undefined || value === null || value === '') {
        callback(new Error('验证码不能为空'))
      } else {
        if (this.codeMessage !== undefined && this.codeMessage !== null && this.codeMessage !== '') {
          callback(new Error(this.codeMessage))
        } else {
          callback()
        }
      }
    }
    return {
      bah: '35020302033920',
      showCodeNum: 0,
      usernameMessage: '',
      passwordMessage: '',
      codeMessage: '',
      codeUrl: '',
      cookiePass: '',
      loginForm: {
        username: '',
        password: '',
        rememberMe: false,
        code: '',
        uuid: ''
      },
      loginRules: {
        /*
        username: [{ required: true, trigger: 'blur', message: '用户名不能为空' }],
        password: [{ required: true, trigger: 'blur', message: '密码不能为空' }],
        code: [{ required: true, trigger: 'change', message: '验证码不能为空' }]
        */
        username: [{ required: true, trigger: 'blur', validator: usernameRule }],
        password: [{ required: true, trigger: 'blur', validator: passwordRule }],
        code: [{ required: true, trigger: 'blur', validator: codeRule }]
      },
      loading: false,
      redirect: undefined
    }
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    this.getCookie()
  },
  methods: {
    jump() {
      window.open('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=' + this.bah)
    },
    getCookie() {
      const username = Cookies.get('username')
      let password = Cookies.get('password')
      const rememberMe = Cookies.get('rememberMe')
      // 保存cookie里面的加密后的密码
      this.cookiePass = password === undefined ? '' : password
      password = password === undefined ? this.loginForm.password : password
      this.loginForm = {
        username: username === undefined ? this.loginForm.username : username,
        password: password,
        rememberMe: rememberMe === undefined ? false : Boolean(rememberMe),
        code: ''
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        const user = {
          username: this.loginForm.username,
          password: this.loginForm.password,
          rememberMe: this.loginForm.rememberMe,
          uuid: this.loginForm.uuid
        }
        if (user.password !== this.cookiePass) {
          // user.password = encrypt(user.password)
        }
        if (valid) {
          this.loading = true
          this.$store
            .dispatch('Login', user)
            .then((res) => {
              if (res && res.code) {
                // 返回错误信息
                if (res.code === 50006) {
                  // 验证码校验
                  this.codeMessage = '验证码过期'
                } else if (res.code === 50007) {
                  this.codeMessage = '验证码错误'
                  this.getCode()
                } else if (res.code === 50005) {
                  // 密码校验
                  this.passwordMessage = '密码错误'
                  Cookies.set(
                    'errorNum',
                    Cookies.get('errorNum') === undefined ? 1 : parseInt(Cookies.get('errorNum')) + 1,
                    { expires: Config.passCookieExpires }
                  )
                } else if (res.code === 50003) {
                  // 账号校验
                  this.usernameMessage = '账号不存在'
                  Cookies.set(
                    'errorNum',
                    Cookies.get('errorNum') === undefined ? 1 : parseInt(Cookies.get('errorNum')) + 1,
                    { expires: Config.passCookieExpires }
                  )
                } else if (res.code === 50004) {
                  // 账号校验
                  this.usernameMessage = '账号已停用'
                  Cookies.set(
                    'errorNum',
                    Cookies.get('errorNum') === undefined ? 1 : parseInt(Cookies.get('errorNum')) + 1,
                    { expires: Config.passCookieExpires }
                  )
                } else {
                  this.$notify.error(res.message)
                }
                this.$refs.loginForm.validate(() => {})
                this.usernameMessage = ''
                this.passwordMessage = ''
                this.codeMessage = ''
                if (parseInt(Cookies.get('errorNum')) === 3 && this.showCodeNum === 0) {
                  this.showCodeNum = Cookies.get('errorNum')
                  this.getCode()
                  Cookies.remove('errorNum')
                } else if (parseInt(Cookies.get('errorNum')) > 0 && this.showCodeNum === '3') {
                  this.getCode()
                  Cookies.remove('errorNum')
                }
              } else {
                Cookies.remove('errorNum')
                this.$router.push({ path: this.redirect !== '/' ? this.redirect : '/home' || '/home' })
              }
              // Cookies.set('applicationId', '354534558195412992')
              this.loading = false
            })
            .catch(() => {
              this.loading = false
              // this.getCode()
            })
        } else {
          console.error('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
$bd-clr2: #dce5ec;
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-image: url(https://docs-1255840532.cos.ap-shanghai.myqcloud.com/3968.jpg);
  background-size: cover;
}
.title {
  margin: 0px auto 30px auto;
  text-align: center;
  color: #707070;
}

/* .login-form {
  border-radius: 6px;
  background: #ffffff;
  width: 400px;
  padding: 25px 25px 5px 25px;
  border: 1px solid $bd-clr2;
  .el-input {
    height: 38px;
    input {
      height: 38px;
    }
  }
  .input-icon {
    height: 45px;
    width: 18px;
    margin-left: 2px;
  }
} */
.login-tip {
  font-size: 13px;
  text-align: center;
  color: #bfbfbf;
}
.login-code {
  width: 33%;
  height: 38px;
  float: right;
  img {
    cursor: pointer;
    vertical-align: middle;
  }
}
.width {
  width: 1200px;
  margin: 0 auto;
}
.header {
  position: fixed;
  width: 100%;
  height: 72px;
  line-height: 50px;
  z-index: 120;
}
.header img {
  width: 32px;
  height: 38px;
  vertical-align: middle;
}
.header h1 {
  display: inline-block;
  font-size: 26px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #fff;
  margin-left: 20px;
  vertical-align: middle;
}
.content {
  position: relative;
  height: calc(100% - 60px);
  // min-width: 1200px;
  background: url('../assets/images/bg.png') top left/auto 100%;
  // padding-top: 836px;
  overflow: hidden;
}
.texiao {
  width: 100%;
  height: 836px;
  // background: url('../../static/img/texiao.png') no-repeat left -130px;
  position: absolute;
  top: 0;
}
.login-form {
  width: 350px;
  // height: 460px;
  height: auto;
  background-color: #ffffff;
  border-radius: 3px;
  position: absolute;
  top: calc(50% - 180px);
  /* right: calc(50% - 635px); */
  right: 13%;
  padding: 30px;
  z-index: 9;
  border: 1px solid $bd-clr2;
}
.login-form h2 {
  margin-top: 8px;
  font-size: 25px;
  line-height: 28px;
  position: relative;
  padding-bottom: 13px;
  font-weight: normal;
  font-family: MicrosoftYaHei, sans-serif;
}
.login-form h2:after {
  content: '';
  width: 100px;
  height: 3px;
  background-color: #0082f8;
  border-radius: 1px;
  position: absolute;
  bottom: 0;
  left: 0;
}
.login-form form {
  padding-top: 10px;
}
.login-form form > div {
  margin-top: 30px;
}
.login-form .login-input {
  position: relative;
}
.login-input:before {
  content: '';
  width: 17px;
  height: 45px;
  position: absolute;
  // background: url('../../static/img/icon_yonghu.png') no-repeat center/100%;
  left: 16px;
}

.login-password,
.login-username {
  width: 290px;
}
.login-code-input {
  width: 60%;
}
.login-form form input[type='text'],
.login-form form input[type='password'] {
  height: 45px;
  line-height: 45px;
  background-color: #ffffff;
  border-radius: 3px;
  border: solid 1px #cccccc;
  padding-left: 50px;
}
.login-form form .check-area {
  color: #666666;
  font-size: 14px;
}
.login-form form .check-area span {
  float: right;
  cursor: pointer;
}
.check-area input[type='checkbox'] {
  vertical-align: middle;
}
.login-form form button {
  width: 290px;
  height: 52px;
  line-height: 3px;
  background-color: #0082f8;
  border-radius: 3px;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  /*margin-top: 40px;*/
}

.footer {
  height: 60px;
  line-height: 60px;
  background-color: #001529;
  color: #becfec;
  text-align: center;
  min-width: 1200px;
}
.footer .footer-2 {
  margin-left: 30px;
}

.swiper-container {
  width: calc(87% - 360px);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 100px;

  .swiper-slide {
    width: 80%;

    img {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
